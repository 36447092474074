<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>{{headerShopName}}-营业收入</span>
      <div style="display:flex;align-items:center;">
        <div class="date-box">
          <div class="box-left" @click="changeReqYear(false)">＜</div>
          <div>{{reqYear}}</div>
          <div class="box-right" @click="changeReqYear(true)">＞</div>
        </div>
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:16px">
        <span style="font-size:14px">营业收入汇总：{{(+totalIncome).toFixed(2)}}</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          @cell-click="clickFun"
          :span-method="objectSpanMethod"
        >
          <el-table-column prop="shopName" label="店铺名称" />
          <el-table-column prop="yearMonth" label="时间" />
          <el-table-column prop="settlement" label="结算汇总" />
          <el-table-column prop="totalIncome" label="营业收入" />
          <el-table-column prop="totalDiscount" label="优惠折扣" />
        </el-table>
      </div>
    </main>
    <PrintNoFrom rul='report/yearReportStatisticsExport.json' :add='"&year="+reqYear+"&shopIds="+shopIds' />
  </div>
</template>

<script>
import {ref} from 'vue'
import {postData} from"@a"
import {useRouter} from 'vue-router'
export default {
  setup(){
    let headerShopName = window.localStorage.getItem('shopName')
    let shopIds = ref(window.localStorage.getItem('shopIds'))
    const router = useRouter()
    const dataOne = ref([
    ])
    let reqYear = ref(new Date().getFullYear())
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      style['text-align'] = 'center'
      return style
    }
    const cellStyle = ({row, column})=>{
      let style  = {}
      style['text-align'] = 'center'
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      if((column.label==="营业收入" || column.label==="优惠折扣") && row.shopName !== "合计"){
        style.color = '#169BD5'
        style['text-decoration'] = 'underline'
        style['cursor'] = 'pointer'
      }
      if(column.label==="店铺名称"){
        style['font-weight'] = 600
      }
      return style
    }
    const clickFun = (row, column)=>{
      console.log(row,column)
      if((column.label==="营业收入" || column.label==="优惠折扣") && row.shopName !== "合计"){
        console.log(row,column,column.label)
        if(column.label==="营业收入"){
          console.log(row.yearMonth)
          router.push({name:'taking',query:{shopName:row.shopName,shopId:row.shopId,time:row.yearMonth}})
        }else{
          router.push({name:'discounts',query:{shopName:row.shopName,shopId:row.shopId,time:row.yearMonth}})
        }
      }
    }
    // 格式化表格店铺名称（合并）
    const objectSpanMethod = ()=>{

      
    }
    const changeReqYear = (val)=>{
      if(val && reqYear.value < new Date().getFullYear()){
        ++reqYear.value
        infoData()
      }else if(!val && reqYear.value > new Date().getFullYear()-1){
        --reqYear.value
        infoData()
      }
    }
    let totalIncome = ref(0)
    const infoData = async()=>{
      try{
        let param = {
          year:reqYear.value,
          shopIds:shopIds.value
        }
        let res = await postData('shop/yearReportStatistics.json',param)
        totalIncome.value = 0
        for(let i=0;i<res.data.length;i++){
          res.data[i].totalIncome = (res.data[i].totalIncome).toFixed(2)
          totalIncome.value += +res.data[i].totalIncome
        }
        dataOne.value = res.data
      } catch(err) {
        console.log(err)
      }
    }
    infoData()
    return{
      dataOne,
      headerStyle,
      cellStyle,
      objectSpanMethod,
      clickFun,
      reqYear,
      changeReqYear,
      headerShopName,
      totalIncome,
      shopIds
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>